@import "../../styles/mixins";

.contact {
  height: 700px;
  background-color: #171a1e;
  padding: 40px 0 80px 0;
  position: relative;
  @include sm {
    height: unset;
    min-height: 100vh;
    padding: 30px 0;
  }
  &_overlay {
    background-color: rgba(#1d2025,.5);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &_modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
  &_container {
    @include container;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-size: 48px;
    margin-bottom: 80px;
    text-transform: uppercase;
    text-align: center;
    @include sm {
      font-size: 36px;
      margin-bottom: 40px;
    }
  }
  &_form {
    width: 1000px;
    @include md {
      width: 100%;
    }
    ::placeholder {
      font-family: 'Roboto';
      font-size: 18px;
      color: #aaaaaa;
    }
  }
}

.form {
  form {
    width: 100%;

  }


  &_input {
    width: 100%;
    padding: 15px 20px;
    background-color: #212429;
    border: none;
    border-bottom: 1px solid #aaaaaa;
    margin-bottom: 20px;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    @include sm {
      margin-bottom: 10px;
    }
  }


  &_name {
    width: 100%;
    display: flex;
    .form_input {
      width: 50%;
      @include sm {
        width: 100%;
      }
      &:first-of-type {
        margin-right: 10px;
        @include sm {
          margin-right: 0;
        }
      }
    }
  }
  &_button {
    button {
      @include blue_button;
      border: 1px solid #52d1e0;
    }
  }
}
textarea {
  margin-bottom: 20px!important;
}

.modal {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  background-color: #1d2025;
  border-radius: 10px;
  z-index: 5;
  box-shadow: 0 4px 10px rgba(black, 0.5);
  position: relative;
  padding: 25px 80px 40px 80px;
  justify-content: space-between;
  text-align: center;
  @include sm {
    width: 300px;
    height: 80vh;
  }
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
  &_cross {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &_button {
    button {
      @include blue_button;
      border: 1px solid #52d1e0;
      width: 100px;
    }
  }
}