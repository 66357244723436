@import "../../styles/mixins";

.header {
  background: rgba(#000000, .3);
  height: 100px;
  padding: 10px 0;
  z-index: 5;
  position: relative;
  @include sm {
    height: 80px;
  }
  .overlay {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    height: 1000vh;
    background-color: rgba(#000, .7);
    z-index: 2;
  }
  &_container {
    @include container;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_burger {
    cursor: pointer;
    position: relative;
    .toggle_burger {
      position: absolute;
      top: 54px;
      left: 0;
      z-index: 3;
      height: 0;
      transition: all .3s;
      ul {
        max-height: 250px;
        overflow-y: scroll;
        background-color: #1d2025;
        width: 200px;
        max-width: 160px;
        padding: 10px;
        border-radius: 4px;
      }
      li {
        cursor: pointer;
        transition: all 0.3s;
        padding: 5px;
        font-size: 14px;
        text-transform: uppercase;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:hover {
          background-color: rgba(#fff, .1);
          color: #fff;
        }
      }
    }
  }
  &_logo {
    cursor: pointer;
    width: 90px;
    height: 80px;
    transition: .3s;
    @include  sm {
      width: 70px;
      height: 60px;
    }
    &:hover {
      transform: scale(1.07);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &_nav {
    ul {
      display: flex;
    }
    li {
      margin-right: 40px;
      transition: .3s;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      @include md {
        margin-right: 25px;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        transform: scale(1.12);
      }
    }
  }
  &_languages {
    display: flex;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    align-items: center;
    .arrow {
      margin-left: 5px;
      img {
        transition: all .3s;
      }
    }
    .toggle_languages {
      position: absolute;
      top: 30px;
      right: 0;
      z-index: 3;
      height: 0;
      transition: all .3s;
      ul {
        max-height: 300px;
        //overflow-y: scroll;
        background-color: #1d2025;
        width: 90px;
        max-width: 160px;
        padding: 10px;
        border-radius: 4px;
      }
      li {
        cursor: pointer;
        transition: all 0.3s;
        padding: 5px;
        font-size: 14px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:hover {
          background-color: rgba(#fff, .1);
          color: #fff;
        }
      }
    }
  }
}