@import "../../styles/mixins";

.catalog {
  min-height: 100vh;
  padding: 40px 0 80px 0;
  &_container {
    @include container;
  }
  h2 {
    text-align: center;
    font-size: 48px;
    margin-bottom: 40px;
    text-transform: uppercase;
    @include sm {
      font-size: 36px;
    }
  }
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include sm {
      font-size: 18px;

    }
  }
  &_section {
    padding-bottom: 40px;
    border-bottom: 5px solid rgba(#fff, 0.1);
    margin-bottom: 40px;
    @include sm {
      padding-bottom: 20px;
    }
  }
  &_desc {
    max-width: 640px;
    font-size: 18px;
    text-transform: lowercase;
    color: #aaaaaa;
    margin-bottom: 40px;
    font-weight: bold;
    line-height: 25px;
    @include sm {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  &_menu {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
      @include md {
        grid-template-columns: 1fr;
      }

      .menu_item {
        width: 500px;
        display: flex;
        box-shadow: 0 4px 4px rgba(black, 0.25);
        @include sm {
          width: 100%;
          flex-direction: column;
        }
      }
      .menu_image {
        width: 320px;
        height: 220px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
        @include sm {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .menu_info {
        width: 200px;
        height: 220px;
        background-color: #171a1e;
        padding: 15px 20px 20px 20px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        @include sm {
          width: 100%;
          padding: 10px 20px 20px 8px;
          height: 200px;
        }
        h3 {
          font-size: 18px;
          margin-bottom: 20px;
          line-height: 25px;
          height: 35px;
        }
        .menu_text {
          p {
            color: #aaaaaa;
            font-size: 14px;
            margin-bottom: 10px;
            &:last-of-type {
              margin-bottom: 25px;
            }
          }
        }
        .menu_button {
          button, a {
            @include blue_button;
            border: 1px solid #52d1e0;
          }
        }
      }
    }
  }
}
.individual {
  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    @include md {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &_img {
    width: 650px;
    height: 540px;
    img {
      max-width: 650px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    @include md {
      width: 700px;
      height: 100%;
      margin-bottom: 20px;
    }
    @include sm {
      width: 100%;
      height: 220px;
      margin-bottom: 20px;
      img {
        max-width: 100%;
      }
    }
  }
  &_cards {
    li {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &_card {
    width: 450px;
    background-color: #171a1e;
    border-radius: 4px;
    padding: 15px 20px;
    height: 170px;
    @include sm {
      width: 100%;
      min-height: 180px;
      padding: 15px 15px 35px 20px;
    }
  }
  &_title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 25px;
    }
  }
  &_desc {
    p {
      font-size: 14px;
      color: #aaa;
      line-height: 23px;
    }
  }
  &_number {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #52d1e0;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;

  }
  &_add {
    max-width: 450px;
    @include sm {
      max-width: 100%;
    }
    p {
      color: #aaaaaa;
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: 30px;
      @include sm {
        font-size: 14px;
      }
    }
    button, a {
      @include blue_button;
      border: 1px solid #52d1e0;
      color: black!important;
    }
  }
}