@import "../../styles/mixins";

.footer {
  height: 560px;
  padding: 40px 0 80px 0;
  background-color: #1d2025;
  @include md {
    height: unset;
  }
  &_container {
    @include container;
  }
  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @include md {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }
    @include sm {
      margin-bottom: 0;
    }
  }
  &_logo {
    width: 200px;
    @include md {
      margin-bottom: 40px;
    }
  }
  &_image {
    margin-bottom: 30px;
    @include sm {
      margin-bottom: 20px;
    }
    img {
      width: 200px;
      height: 192px;
    }
  }
  &_socials {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &_contacts {
    ul {
      display: grid;
       grid-template-columns: 1fr 1fr 1fr;
       gap: 20px;

      @include md {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
      }
      @include sm {
        display: flex;
        margin-right: 0;
        margin-bottom: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // width: 200px;
      }
      li {
        margin-right: 30px;
        @include sm {
          margin-bottom: 30px;
          margin-right: 0;
          width: 100%;
        }
        &:last-of-type {
          margin-right: 0;
          @include sm {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include sm {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  &_sign {
    margin-bottom: 20px;
    width: 150px;
    height: 55px;
    @include sm {
      text-align: center;
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &_agency {
    p {
      font-size: 18px;
      line-height: 23px;
    }
  }
  &_developers {
    font-size: 18px;
    span {
      color: #52d1e0;
      text-decoration: underline;
      cursor: pointer;
    }
    @include sm {
      margin-top: 60px;
    }
  }
}
.contacts {
  &_cart {
    width: 240px;
    height: 185px;
    @include sm {
      height: unset;
    }
    h4 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  &_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      margin-right: 25px;
      width: 30px;
      height: 30px;
    }
    p {
      font-size: 18px;
      color: #aaaaaa;
      line-height: 23px;
      text-align: left;
    }
  }
}
