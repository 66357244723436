@import "../../styles/mixins";

.whyUs {
  min-height: 550px;
  padding: 40px 0 60px 0;
  background-color: #171a1e;
  @include sm {
    min-height: 490px;
    padding: 40px 0 80px 0;
  }
  &_container {
    @include container;
  }
  h2 {
    font-size: 48px;
    margin-bottom: 70px;
    text-transform: uppercase;
    text-align: center;
    @include sm {
      font-size: 36px;
      margin-bottom: 40px;
    }
  }
  &_list {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include md {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        flex-wrap: nowrap;
      }
    }
    ul::-webkit-scrollbar {
      display: none;
    }
    li {
      margin-right: 15px;
    }
  }
  &_item {
    width: 285px;
    height: 250px;
    background-color: #1d2025;
    border-radius: 5px;
    transition: .3s;
    margin-bottom: 30px;
    padding: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 10px rgba(black, 0.5);
    img {
      width: 26px;
      height: 28px;
    }
    //justify-content: space-between;
    @include sm {
      width: 300px;
      height: 250px;
      padding: 20px 15px 50px 15px;
      margin-bottom: 0;
    }
    &:hover {
      transform: scaleY(1.05);
      img {
        opacity: 1;
      }
    }
    h3 {
      font-size: 18px;
      text-transform: uppercase;
      line-height: 40px;
      @include sm {
        height: 40px;
        line-height: 40px;
        margin-bottom: 24px;
        font-size: 18px;
      }
    }
    p {
      font-size: 14px;
      color: #aaaaaa;
      line-height: 20px;
      @include sm {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &_heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    height: 60px;
    align-items: flex-start;
    @include sm {
      align-items: flex-start;
      margin-bottom: 10px;
    }
    img {
      opacity: 0.5;
    }
  }
}