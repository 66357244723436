@import "../../styles/mixins";

.hero {
  height: 100vh;
  position: relative;
  @include sm {
    height: 60vh;
  }
  .video {
    top: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  &_container {
    @include container;
    padding-top: 140px;
    @include sm {
      padding-top: 50px;
    }
  }
  &_link {
    @include transparent_button;
  }
  &_text {
    position: absolute;
    z-index: 2;
  }
  h1 {
    font-size: 48px;
    text-transform: uppercase;
    margin-bottom: 20px;
    @include sm {
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 25px;
    }
  }
  h3 {
    max-width: 700px;
    font-size: 24px;
    margin-bottom: 30px;
    @include sm {
      font-size: 14px;
      margin-bottom: 25px;
      max-width: 80%;
    }
  }
  button {
    @include transparent_button;
  }
  &_overlay {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 0;
    //background: linear-gradient( transparent 0%, #1d2025 100%);
    background: rgba(#1d2025, 0.2);
    @include sm {
      height: 100vh;
    }
  }
  &_gradient {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 250px;
    z-index: 1;
    background: linear-gradient(transparent 0%, #1d2025 100%);
    @include sm {
      height: 100px;
    }
  }
}
