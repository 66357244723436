@import "../../styles/mixins";

.gallery {
  padding: 40px 0;
  background-color: #171a1e;
  &_container {
    @include container;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include sm {
      //width: unset;
    }
  }
  &_list {
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 40px;
    }
    ul::-webkit-scrollbar {
      display: none;
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px 20px;
      grid-template-areas:
              "pic-1 pic-2 pic-3"
              "pic-4 pic-4 pic-5"
              "pic-4 pic-4 pic-6"
              "pic-7 pic-9 pic-9"
              "pic-8 pic-9 pic-9"
              "pic-10 pic-11 pic-12";
              //"pic-13 pic-13 pic-14"
              //"pic-13 pic-13 pic-15";
      @include sm {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        flex-wrap: nowrap;
        width: 100%;
      }

      li {
        @include sm {
          margin-right: 20px;
          width: 300px;
          height: 220px;
          max-height: 220px;
          max-width: 300px;
          min-height: 220px;
          min-width: 300px;
          display: inline-block;
        }
      }
    }
  }
  &_button {
    button {
      @include blue_button;
      border: 1px solid #52d1e0;
    }
    a {
      @include blue_button;
      border: 1px solid #52d1e0;
      color: black!important;
      background-color: #52d1e0!important;
    }
  }
  &_link {
    a {
      @include blue_button;
      border: 1px solid #52d1e0;
      color: black!important;
      background-color: #52d1e0!important;
    }
  }
}

.pic-4 {
  grid-area: pic-4!important;
}
.pic-9 { grid-area: pic-9; }
.pic-1 { grid-area: pic-1; }
.pic-2 { grid-area: pic-2; }
.pic-3 { grid-area: pic-3; }
.pic-5 { grid-area: pic-5; }
.pic-6 { grid-area: pic-6; }
.pic-7 { grid-area: pic-7; }
.pic-8 { grid-area: pic-8; }
.pic-10 { grid-area: pic-10; }
.pic-11 { grid-area: pic-11; }
.pic-12 { grid-area: pic-12; }
.pic-13 { grid-area: pic-13; }
.pic-14 { grid-area: pic-14; }
.pic-15 { grid-area: pic-15; }

